import logo from './logo.svg';
import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import {
  RedirectToSignIn,
  SignedIn,
  SignedOut,
  RedirectToUserProfile,
  ClerkProvider
} from '@clerk/clerk-react';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import ChatPage from './pages/ChatPage';

const PUBLISHABLE_KEY = process.env.NODE_ENV === 'development' ? 'pk_test_ZXBpYy1hbnQtODQuY2xlcmsuYWNjb3VudHMuZGV2JA' : 'pk_test_ZXBpYy1hbnQtODQuY2xlcmsuYWNjb3VudHMuZGV2JA';

function App() {

  return (
    <ClerkProvider
      publishableKey={PUBLISHABLE_KEY}>
      <Routes>
        <Route path="/" element={<>
          <SignedOut>
            <LoginPage />
          </SignedOut>
          <SignedIn>
            <ChatPage />
          </SignedIn>
        </>}
        />
        <Route path="/signup" 
        element={
          <>
          <SignedOut>
            <SignUpPage />
          </SignedOut>
          <SignedIn>
            <ChatPage />
          </SignedIn>
          </>
        } />
        <Route path="/chat" 
          element={
            <>
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
              <SignedIn>
                <ChatPage />
              </SignedIn>  
            </>                  
          } />
      </Routes>
    </ClerkProvider>
  );
}


export default App;
