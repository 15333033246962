import React, { useState, useEffect, useRef } from 'react';
import BASE_URL from '../utils/config';
import { SignOutButton, SignInButton, SignedIn, SignedOut, SignIn } from "@clerk/clerk-react"
import {dark} from "@clerk/themes";

const LoginPage = () => {


    return (
            <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>       
                    <SignIn
                        redirectUrl={'/chat'}
                        signUpUrl='/signup'
                    />
            </div>            
    )

}


export default LoginPage;