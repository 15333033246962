import React, { useState, useEffect, useRef } from 'react';
import BASE_URL from '../utils/config';
import Vapi from "@vapi-ai/web";
import { useAuth, useUser, UserButton } from '@clerk/clerk-react';


export const vapi = new Vapi("e3b9973f-84fd-46f9-99de-39ab099fb8a6"); // Get Public Token from Dashboard > Accounts Page

const INTERNAL_ID = "ad82e4a3-6184-41c0-a5fb-cc2c6e043ae3"
const EXTERNAL_ID = "11c34534-13b0-4d8a-8e1b-17998ac39448"

const ChatPage = () => {
    const { user } = useUser();
    const email = user.primaryEmailAddress.emailAddress;
    const INTERNAL_EMAILS = ['kayjewellmd@gmail.com', 'gnw@smt-inc.net', 'nevin@smt-inc.net', 'nevinw@wellbeing1.com'];
    const isInternal = INTERNAL_EMAILS.includes(email);
    const [callStatus, setCallStatus] = useState("inactive");
    const [assistantTranscript, setAssistantTranscript] = useState("");



    const start = async () => {
        setCallStatus("loading");
        const response = vapi.start(isInternal ? INTERNAL_ID : EXTERNAL_ID);
        setAssistantTranscript('');
    };
    const stop = () => {
        setCallStatus("loading");
        vapi.stop();
        setAssistantTranscript('');
    };
    useEffect(() => {
        vapi.on("call-start", () => {
                setCallStatus("active");
                setAssistantTranscript('');
            });
        vapi.on("call-end", () => 
            {
                setCallStatus('inactive');
                setAssistantTranscript('');
            });
        
        return () => vapi.removeAllListeners();
    }, []);

    vapi.on("message", (message) => {
        console.log(message);
        if (message.type === 'transcript' && message.role === 'assistant') {
            setAssistantTranscript(message.transcript.replace('Hijia', 'Hygia'));
        }
        
    });   

    const microphoneStart = () => {

        return (
        <svg width="154" height="154" viewBox="0 0 154 154" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx="77" cy="77" r="76.5" stroke="url(#paint0_linear_54_1346)"/>
            <circle opacity="0.2" cx="77" cy="77" r="62.5" stroke="url(#paint1_linear_54_1346)"/>
            <circle opacity="0.5" cx="77" cy="77" r="47.5" stroke="url(#paint2_linear_54_1346)"/>
            <path d="M77 62.75C74.3766 62.75 72.25 64.8766 72.25 67.5V75.4166C72.25 78.04 74.3766 80.1666 77 80.1666C79.6234 80.1666 81.75 78.04 81.75 75.4166V67.5C81.75 64.8766 79.6234 62.75 77 62.75ZM78.5833 89.5797V92.0416C78.5833 92.9161 77.8745 93.625 77 93.625C76.1255 93.625 75.4167 92.9161 75.4167 92.0416V89.5797C68.2917 88.7921 62.75 82.7515 62.75 75.4166C62.75 74.5422 63.4589 73.8333 64.3333 73.8333C65.2078 73.8333 65.9167 74.5422 65.9167 75.4166C65.9167 81.5378 70.8788 86.5 77 86.5C83.1212 86.5 88.0833 81.5378 88.0833 75.4166C88.0833 74.5422 88.7922 73.8333 89.6667 73.8333C90.5411 73.8333 91.25 74.5422 91.25 75.4166C91.25 82.7515 85.7083 88.7921 78.5833 89.5797ZM77 59.5833C81.3723 59.5833 84.9167 63.1277 84.9167 67.5V75.4166C84.9167 79.7889 81.3723 83.3333 77 83.3333C72.6277 83.3333 69.0833 79.7889 69.0833 75.4166V67.5C69.0833 63.1277 72.6277 59.5833 77 59.5833Z" fill="url(#paint3_linear_54_1346)"/>
            <defs>
            <linearGradient id="paint0_linear_54_1346" x1="77" y1="0" x2="77" y2="154" gradientUnits="userSpaceOnUse">
            <stop stopColor="#38DBFF"/>
            <stop offset="1" stopColor="#7738FF"/>
            </linearGradient>
            <linearGradient id="paint1_linear_54_1346" x1="77" y1="14" x2="77" y2="140" gradientUnits="userSpaceOnUse">
            <stop stopColor="#38DBFF"/>
            <stop offset="1" stopColor="#7738FF"/>
            </linearGradient>
            <linearGradient id="paint2_linear_54_1346" x1="77" y1="29" x2="77" y2="125" gradientUnits="userSpaceOnUse">
            <stop stopColor="#38DBFF"/>
            <stop offset="1" stopColor="#7738FF"/>
            </linearGradient>
            <linearGradient id="paint3_linear_54_1346" x1="77" y1="59.5833" x2="77" y2="93.625" gradientUnits="userSpaceOnUse">
            <stop stopColor="#38DBFF"/>
            <stop offset="1" stopColor="#7738FF"/>
            </linearGradient>
            </defs>
        </svg>
        )

    }


    const microphoneListening = () => {

        return (
            <div className="microphone-shape">
                <svg width="154" height="154" viewBox="0 0 154 154" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle opacity="0.1" cx="77" cy="77" r="76.5" stroke="url(#paint0_linear_54_1369)"/>
                    <circle opacity="0.2" cx="77" cy="77" r="62.5" stroke="url(#paint1_linear_54_1369)"/>
                    <circle opacity="0.4" cx="77" cy="77" r="48" fill="url(#paint2_linear_54_1369)"/>
                    <path d="M77 62.75C74.3766 62.75 72.25 64.8766 72.25 67.5V75.4166C72.25 78.04 74.3766 80.1666 77 80.1666C79.6234 80.1666 81.75 78.04 81.75 75.4166V67.5C81.75 64.8766 79.6234 62.75 77 62.75ZM78.5833 89.5797V92.0416C78.5833 92.9161 77.8745 93.625 77 93.625C76.1255 93.625 75.4167 92.9161 75.4167 92.0416V89.5797C68.2917 88.7921 62.75 82.7515 62.75 75.4166C62.75 74.5422 63.4589 73.8333 64.3333 73.8333C65.2078 73.8333 65.9167 74.5422 65.9167 75.4166C65.9167 81.5378 70.8788 86.5 77 86.5C83.1212 86.5 88.0833 81.5378 88.0833 75.4166C88.0833 74.5422 88.7922 73.8333 89.6667 73.8333C90.5411 73.8333 91.25 74.5422 91.25 75.4166C91.25 82.7515 85.7083 88.7921 78.5833 89.5797ZM77 59.5833C81.3723 59.5833 84.9167 63.1277 84.9167 67.5V75.4166C84.9167 79.7889 81.3723 83.3333 77 83.3333C72.6277 83.3333 69.0833 79.7889 69.0833 75.4166V67.5C69.0833 63.1277 72.6277 59.5833 77 59.5833Z" fill="url(#paint3_linear_54_1369)"/>
                    <defs>
                    <linearGradient id="paint0_linear_54_1369" x1="77" y1="0" x2="77" y2="154" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#38DBFF"/>
                    <stop offset="1" stopColor="#7738FF"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_54_1369" x1="77" y1="14" x2="77" y2="140" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#38DBFF"/>
                    <stop offset="1" stopColor="#7738FF"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_54_1369" x1="77" y1="29" x2="77" y2="125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#38DBFF"/>
                    <stop offset="1" stopColor="#7738FF"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_54_1369" x1="77" y1="59.5833" x2="77" y2="93.625" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#38DBFF"/>
                    <stop offset="1" stopColor="#7738FF"/>
                    </linearGradient>
                    </defs>
                </svg>
            </div>
        )
    }

    return (
        <>
            <div style={{position: 'absolute', top: '0', right: '0', margin: '0.4%'}}>
                <UserButton />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{height: '100vh'}}>       
                <div className="mb-4"><img style={{width: '150px', height: '150px'}} src="/hygia.png"></img></div>
                <div className="mb-1">{assistantTranscript}</div>
                {callStatus === "inactive" ? (<div onClick={start}>{microphoneStart()}<div className="text-center mt-4 text-bold">Start</div></div>) : null}
                {callStatus === "loading" ? <i>Loading...</i> : null}
                {callStatus === "active" ? (<div onClick={stop}>{microphoneListening()}<div className="text-center mt-4">Listening</div></div>) : null}                
            </div>
        </>            
    )

}


export default ChatPage;